import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Contact from '../containers/Contact'

type ContactPageProps = {}

const ContactPage: React.FunctionComponent<ContactPageProps> = props => {
  return (
    <Layout>
      <SEO title="Contact" description="Get into contact with me" />
      <Contact />
    </Layout>
  )
}

export default ContactPage
