import * as React from 'react'

import { ContactWrapper } from './style'
import SocialProfile from '../../../components/SocialProfile/SocialProfile'
import { IoLogoTwitter } from 'react-icons/io'

const SocialLinks = [
  {
    icon: <IoLogoTwitter />,
    url: 'https://twitter.com/iamtylerwclark',
    tooltip: 'Twitter'
  }
]

const Contact: React.SFC<{}> = () => {
  return (
    <ContactWrapper>
      <h2>Want to get in contact with me? </h2>
      <div style={{ display: 'flex' }}>
        <p>
          Currently the best way to get into contact with me is through a direct
          message on twitter.
        </p>
        <SocialProfile items={SocialLinks} />
      </div>
      <br />
    </ContactWrapper>
  )
}

export default Contact
