import styled from "styled-components"

export const ContactWrapper = styled.div`
  width: 870px;
  margin: 0 auto;
  padding-top: 120px;
  max-width: 100%;
  @media (max-width: 990px) {
    padding: 80px 45px 30px 45px;
  }
  @media (max-width: 575px) {
    padding: 60px 25px 0 25px;
  }
`
